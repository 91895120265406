import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { getProducts, getProductPath } from "utils/products"

const Container = styled.div`
  ${tw`bg-grey-10 font-body text-white text-center fixed flex flex-row items-center justify-center`}
  box-shadow: inset -2px -2px 3px 0 rgba(110,110,110,0.05), inset 2px 2px 3px 0 rgba(110,110,110,0.05);
  font-size: 15px;
  height: ${(props) => (props.sticky ? "60px" : 0)};
  line-height: 17px;
  letter-spacing: 0;
  transition: all 0.2s;
  top: 80px;
  width: 100%;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    z-index: ${(props) => (props.sticky ? 49 : -1)};
    opacity: ${(props) => (props.sticky ? 1 : 0)};
  }
`

const ProductFrame = styled.div`
  ${tw`p-4 px-5 flex flex-col items-center justify-center`}
  background: ${(props) =>
    props.selected ? "linear-gradient(180deg, #e3284c 0%, #aa0024 100%)" : ""};
  ${(props) =>
    props.selected ? "box-shadow: 0 0 5px 0 rgba(170,0,36,0.66);" : ""};
  ${(props) => (props.selected ? "color: white;" : "")};
  clip-path: polygon(16px 0%, 100% 0%, calc(100% - 16px) 100%, 0% 100%);
  cursor: pointer;
  height: 60px;
  margin-left: -8px;
  margin-right: -8px;
  max-width: 120px;
  position: relative;
  transition: all 0.2s;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) =>
      props.selected
        ? ""
        : "linear-gradient(180deg, #262626 0%, #262626 100%)"};
    z-index: -1;
    transition: opacity 0.1s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
`

const Product = ({ selected, path, children }) => {
  return (
    <Link to={path}>
      <ProductFrame selected={selected}>
        <span>{children}</span>
      </ProductFrame>
    </Link>
  )
}

const ProductsHeader = ({ current }) => {
  const [isStuck, setSticky] = useState(false)

  const query = graphql`
    query ProductsHeader {
      gcms {
        pages(
          where: {
            path_in: [
              "/products/tv-demand"
              "/products/talent-demand"
              "/products/movie-demand"
            ]
          }
          orderBy: order_ASC
        ) {
          id
          headerTitle
          path
        }
      }
    }
  `

  const data = useStaticQuery(query)

  const products = getProducts(data.gcms.pages)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const sticky = currPos.y > 350
      if (sticky !== isStuck) {
        setSticky(sticky)
      }
    },
    [isStuck],
    false,
    true,
    300
  )

  return (
    <Container sticky={isStuck}>
      {products.map(({ id, path, headerTitle }) => {
        return (
          <Product
            path={getProductPath(path)}
            key={id}
            selected={path === current}
          >
            {headerTitle}
          </Product>
        )
      })}
    </Container>
  )
}

export default ProductsHeader
