import React from "react"
import tw, { styled } from "twin.macro"

import PageHeaderContentStyles from "components/page-header-content-styles"
import ProductDotsBg from "images/bg/product-dots-blurred.jpg"

export const HeaderBackground = styled.div`
  ${tw`relative`}

  background: ${(props) => `
    linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 55.11%),
    linear-gradient(68.48deg, rgba(0, 0, 0, 0.75) 2.02%, rgba(0, 0, 0, 0) 97.78%),
    url(${ProductDotsBg}),
    linear-gradient(115.78deg, ${props.headerStart} -4.73%, ${props.headerEnd} 99.28%)
  `};
  background-blend-mode: normal, normal, soft-light, normal;
  background-position: center, center, center, center;
  background-size: auto auto, auto auto, cover, auto auto;
  /*filter: blur(4px);*/

  &::before {
    ${tw`absolute`}

    content: "";
    width: 50%;
    left: 0px;
    top: 0px;
    bottom: 0px;

    background: linear-gradient(
      180deg,
      rgba(0, 4, 8, 0) 0.33%,
      rgba(0, 0, 0, 0.3) 100%
    );
    background-blend-mode: overlay;
    clip-path: polygon(0 45%, 0% 100%, 100% 100%);

    @media (min-width: 640px) {
      clip-path: polygon(0% 0%, 35% 0%, 100% 100%, 0% 100%);
    }
  }

  &::after {
    ${tw`absolute`}

    content: "";
    width: 50%;
    right: 0px;
    top: 0px;
    bottom: 0px;

    background: ${(props) => `
      linear-gradient(180deg, rgba(0, 4, 8, 0.34) 0.33%, ${props.headerRight} 100%);
    `};
    clip-path: polygon(100% 45%, 0% 100%, 100% 100%);

    @media (min-width: 640px) {
      clip-path: polygon(65% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  .hero {
    ${tw`relative flex flex-col items-center`}

    img {
      z-index: 1;
      height: 166px;

      @media (min-width: 640px) {
        height: 370px;
      }
    }
  }

  .center-content {
    ${tw`pt-16 m:pt-32 px-2 flex flex-col justify-center items-center
         content-center mx-auto max-w-s m:max-w-m l:max-w-m relative`}

    z-index: 1;

    .demand360 img {
      height: 30px;
    }
  }
`

const ProductPageHeader = ({ page }) => {
  const { headerImg, headerContent, extraData } = page
  const content = headerContent?.html
  const { colors = {} } = extraData

  return (
    <HeaderBackground {...colors}>
      <div className="center-content">
        {content && (
          <PageHeaderContentStyles
            className="product"
            dangerouslySetInnerHTML={{ __html: `<div>${content}</div>` }}
          />
        )}
      </div>
      {headerImg && (
        <div className="hero">
          <img src={headerImg.url} />
        </div>
      )}
    </HeaderBackground>
  )
}

export default ProductPageHeader
