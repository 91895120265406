import React from "react"
import tw, { styled } from "twin.macro"

const PageHeaderContentStyles = styled.div`
  ${tw`pt-16 pb-32 text-center`}

  &.product, &.product-group {
    ${tw`mt-12 pb-10 l:pb-0`}

    p {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &.solutions {
    ${tw`pb-0`}
  }

  &.product {
    ${tw`pt-24`}
  }

  &.product-group {
    ${tw`mx-auto mt-0 mb-4 m:mb-0 l:mb-16 pt-32`}

    max-width: 900px;

    &.press-coverage {
      ${tw`pt-32 l:pt-44`}
    }
  }

  h1 {
    ${tw`py-2 font-display text-white`}
    font-size: 30px;
    line-height: 35px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px;
    }
    @media (min-width: 1024px) {
      font-size: 60px;
      line-height: 79px;
    }
  }

  h3 {
    ${tw`font-display text-yellow `}
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 640px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  p {
    ${tw`font-body text-white`}
    font-size: 1.125rem;
    line-height: 1.5;
    text-align: center;
    margin-top: 0.25rem;
    @media (min-width: 640px) {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-top: 0.75rem;
    }
  }
`

export default PageHeaderContentStyles
