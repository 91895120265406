import React from "react"
import styled from "styled-components"

const Slant = styled.div`
  position: relative;
  height: calc(100% + 100px);
  margin-top: -10px;
  clip-path: polygon(0% 10px, 100% 0%, 100% 100%, 0% 100%);

  @media (min-width: 640px) {
    margin-top: -45px;
    clip-path: polygon(0% 45px, 100% 0%, 100% 100%, 0% 100%);
  }

  & > div {
    position: relative;
    z-index: 1;
  }
`
const SlantedSection = ({ children, ...rest }) => (
  <Slant {...rest}>
    <div>{children}</div>
  </Slant>
)

export default SlantedSection
